import { useTranslation } from 'react-i18next';
import { AppRoute } from 'app/routesEnum';
import { CarrierTier } from 'shared/enums/carrier-tier.enum';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LoadReleaseScheduleTimer } from '../load-release-schedule/load-release-schedule-timer.component'
import { getScheduledReleaseDateTime } from 'app/util/loads/scheduled-release-function';
import { useState } from 'react';

import './cap-restricted.component.scss';

interface Props {
  loadCarrierTier: string;
  userCarrierTier?: string;
  openToCoreDateTime?: Date;
  openToBaseDateTime?: Date;
  variant: 'locked' | 'restricted';
}

export const CapRestricted = ({ loadCarrierTier, userCarrierTier, openToCoreDateTime, openToBaseDateTime, variant }: Props) => {
  const isKeyLoad: boolean = (loadCarrierTier ?? 'None').toUpperCase() == CarrierTier.KEY;
  const { enableLoadDetailsShotClock } = useFlags();
  const [timerElapsed, setTimerElapsed] = useState(false);
  const { t } = useTranslation();

  // If flag is on, calculate timer value
  const scheduledReleaseDateTime: Date = enableLoadDetailsShotClock ?
    getScheduledReleaseDateTime(loadCarrierTier, userCarrierTier, openToCoreDateTime, openToBaseDateTime) : undefined;

  if (variant == 'restricted') {
    return <div className="cap-restricted" data-testid="cap-restricted">
      <img className="cap-lock" src="/assets/images/Lock.svg" data-testid="restricted-variant-image" />
      <div>
        {t('CAP_RESTRICTED_DETAIL')}
      </div>
    </div>;
  }
  else if (variant == 'locked') {
    return <div className={`${loadCarrierTier} cap-restricted`} data-testid="cap-locked">
      {(enableLoadDetailsShotClock && scheduledReleaseDateTime) ?
        <div>
          {timerElapsed ?
            <div>
              {t("CAP_SCHEDULED_RELEASE_REFRESH", { 0: userCarrierTier.slice(0, 1).toUpperCase() + userCarrierTier.slice(1).toLowerCase() })}
            </div>
            :
            <>
              <img className="cap-lock" src={isKeyLoad ? "/assets/images/lock_orange.svg" : "/assets/images/lock_blue.svg"} data-testid="locked-variant-image" />
              <span data-testid="load-carrier-tier"><b>{loadCarrierTier} Load </b>
                <LoadReleaseScheduleTimer
                  loadReleaseSchedule={scheduledReleaseDateTime}
                  isTiered={true}
                  loadCarrierTier={(loadCarrierTier ?? 'None').toUpperCase()}
                  userCarrierTier={userCarrierTier}
                  onTimeout={() => setTimerElapsed(true)}
                />
              </span>
            </>
          }
        </div>
        :
        <>
          <img className="cap-lock" src={isKeyLoad ? "/assets/images/lock_orange.svg" : "/assets/images/lock_blue.svg"} data-testid="locked-variant-image" />
          <div>
            <span data-testid="load-carrier-tier"><b>{loadCarrierTier} Load</b> {t('CAP_RELEASES_TIER', { 0: userCarrierTier })} </span>
            <a href={AppRoute.DASHBOARD} data-testid="dashboard-link">
              {t('VIEW_CARRIER_STATUS')}
            </a>
          </div>
        </>
      }
    </div>
  }
};
