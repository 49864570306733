import { useTranslation, Trans } from 'react-i18next';
import { Button } from 'shared/components/elements/elements.components';
import { CommoditiesModal } from 'shared/components/load-commodity/commodities-modal.component';
import { ModalNames } from '@app/globals/constants';
import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';
import { showModal } from 'shared/components/modal/modal.actions';
import './commodities.component.scss';

interface OwnProps {
  items: AvailableLoadItemJSON[];
}

export const Commodities = ({ items }: OwnProps) => {
  const showCommoditiesModal = useDispatcher(showModal);
  const { t } = useTranslation();
  const firstComponentName = items[0]?.commodityName;
  const moreThanOne = items.length > 1;

  return (
    <div className="commodity-section">
      <div className="separator-line" />
      <div className="title" role="heading" aria-level={3}>
        {t('COMMODITY')}
      </div>
      <div className="commodity-info">
        <div className="commodity-name">
          {firstComponentName ? firstComponentName : t('NO_COMMODITY_INFO_AVAILABLE')}
        </div>
        {moreThanOne &&
          <div className="multiple-commodities">
            <Trans i18nKey="PLUS_NUMBER_MORE" components={[<span> {items.length - 1}</span>]} />
          </div>
        }
      </div>
      {moreThanOne &&
        <Button
          track
          btnLink
          id="commodity-view-all-link"
          onClick={() => showCommoditiesModal(ModalNames.Commodities_Modal)}
          data-testid="commodity-view-all-link"
        >
          {t('VIEW_ALL')}
        </Button>
      }
      <CommoditiesModal commodities={items} />
    </div>
  );
};
